<template>
  <div>
    <van-cell-group style="margin-top:1px">
      <van-cell style="padding:15px 10px 16px 10px">
        <template #title>
          <span class="custom-title hidden-text">
            <svg-icon icon-class="layers" size="18" style="margin-right:8px" /> {{row.schshiName}}
          </span>
        </template>
        <template #right-icon>
          <div class="status-div">
            <span class="status-div-text">{{row.status === '1' ?"已开启":"未开启"}}</span>
          </div>
        </template>
      </van-cell>
    </van-cell-group>
    <van-field v-model="row.schshiNo" readonly label="项目代码" />
    <van-field v-model="typeName" readonly label="项目类型" />
    <van-field v-model="row.startTime" readonly label="开始时间" />
    <van-field v-model="row.endTime" readonly label="结束时间" />
    <van-field v-model="row.remarks" readonly rows="3" class="textarea-style" label="备注" type="textarea" />
    <!-- 提交 -->
    <!-- <div class="submit-button shadow-style">
      <van-button round block type="primary" :disabled="isDisable()" @click="doAdd()">申 请</van-button>
    </div> -->
  </div>
</template>

<script>
import { isDuringDate, getTermYear, getTerm } from "@/utils/index";
import { getList } from "@/api/modules/award/stujxj";
import { webService } from "@/config/settings";
import { mapGetters } from "vuex";

export default {
  name: "ProjectDetail",
  components: {},
  dicts: ["xj_xqgl"],
  data() {
    return {
      row: {},
      typeName: "",
    };
  },
  computed: {
    ...mapGetters(["token"]),
  },
  created() {
    let data = this.$route.query;
    this.row = data;
    this.$nextTick((e) => {
      let type = data.typeNo === "jxj" ? "奖学金" : "助学金";
      this.typeName = type;
    });
  },
  methods: {
    isDuringDate,
    isDisable() {
      if (this.row.status !== "1") {
        return true;
      }
      return !isDuringDate(this.row.startTime, this.row.endTime);
    },
    doAdd() {
      let data = this.$route.query;
      let _this = this;
      let termYear = getTermYear(
        _this.dict.xj_xqgl,
        (new Date().getMonth() + 1).toString()
      );
      let term = getTerm(
        _this.dict.xj_xqgl,
        (new Date().getMonth() + 1).toString()
      );
      let xqsqcs = "";
      data.jxjdjglList.forEach((item) => {
        if (item.gradeCode === term.xqCode) {
          xqsqcs = item.pbv;
        }
      });
      getList({
        schshiId: data.id,
        termYear: termYear,
        typeNo: data.typeNo,
      }).then((res) => {
        if (data.isFreq === "true" && parseInt(data.freq) <= res.length) {
          //每年
          _this.$notify({
            type: "warning",
            message:
              "已达到申请上限，要求每年" +
              data.freq +
              "次，当前已申请" +
              res.length +
              "次，不可再申请！",
            duration: 1500,
          });
          return;
        }
        let dqsq = res.filter((item) => {
          return item.teremCode === term.xqCode;
        });
        if (data.isFreq === "false" && parseInt(xqsqcs) <= dqsq.length) {
          //每学期
          _this.$notify({
            type: "warning",
            message:
              "已达到申请上限，要求每学期" +
              xqsqcs +
              "次，当前已申请" +
              dqsq.length +
              "次，不可再申请！",
            duration: 1500,
          });
          return;
        }
        if (data.isCustom === "true") {
          let param =
            "id=" +
            data.id +
            "&title=" +
            data.schshiName +
            "&stu=yes&meta=奖助申请" +
            "&schshiNo=" +
            data.schshiNo +
            "&schshiName=" +
            data.schshiName +
            "&typeNo=" +
            data.typeNo +
            "&apkToken=" +
            this.token;
          window.location.href = webService + "/#/app/custom/form?" + param;
        } else {
          _this.$router.push({
            path: "/xs/a05/" + data.schshiNo + "/form",
          });
        }
      });
    },
  },
};
</script>

<style  lang='scss' scoped>
.textarea-style {
  height: 100% !important;
}
.status-div {
  width: 60px;
  height: 22px;
  background-color: rgba(35, 171, 114, 0.16);
  border-radius: 30px;
  text-align: center;
  &-text {
    color: rgba(35, 171, 114, 1);
  }
}
</style>
